<template>
  <tr>
    <th v-if="filter == 'approved'">
      <el-checkbox @mouseup="toggleSelected(submission.id)" />
    </th>
    <th>
      <p>{{ submission.form.customer.name }}</p>
    </th>
    <th>
      <p>{{ submission.form.plaintiffName }}</p>
    </th>
    <th>
      <p v-for="(item, index) in submission.form.defendants" :key="index">
        {{ item.first_name[0] + ' ' + item.last_name }}<br />
      </p>
    </th>
    <th>
      <p>{{ submission.form.unit }} {{ submission.form.street }}</p>
    </th>
    <th>
      <p>{{ submission.form.caseType.replace('-', ' ') }}</p>
    </th>
    <th>
      <p v-for="(item, index) in submission.files" :key="index">
        <a :href="item.url" target="_blank">{{ item.name }}</a>
      </p>
    </th>
    <th>
      <p>{{ submission.status }}</p>
    </th>
    <th><Button text="Summary" :url="submission.id" /></th>
  </tr>
</template>

<script>
  import Button from '@/components/atoms/BlueButton'

  export default {
    name: 'CaseSummary',
    components: {
      Button,
    },
    props: {
      submission: Object,
      filter: String,
      selectedIds: Array,
    },
    data() {
      var filteredBalance = this.submission.form.balanceThrough.split('-')
      filteredBalance.pop()
      return {
        balanceThrough: filteredBalance,
        selected: this.selectedIds,
      }
    },
    methods: {
      toggleSelected(id) {
        const index = this.selected.indexOf(id)
        if (index > -1) {
          this.selected.splice(index, 1)
        } else {
          this.selected.push(id)
        }
      },
    },
  }
</script>

<style scoped>
  th {
    align-items: center;
    padding: 10px 10px;
    border: 1px solid #000;
  }

  p {
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
  }

  a {
    color: blue;
  }
</style>
