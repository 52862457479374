<template>
  <div>
    <div :class="isFormLoading ? 'loader' : 'not-loader'">
      <pulse-loader
        :loading="isFormLoading"
        :color="loaderColor"
      ></pulse-loader>
    </div>
    <div v-if="!isFormLoading" class="center">
      <div class="content">
        <h1>Submissions</h1>
        <div style="width: 400px; margin-bottom: 10px">
          <div style="width: 200px; float: left">
            <el-select
              v-model="filterSelected"
              class="m-2"
              placeholder="Pending"
              @change="updateFilter"
            >
              <el-option label="Pending" value="pending" />
              <el-option label="Approved" value="approved" />
              <el-option label="Processed" value="processed" />
              <el-option label="Rejected" value="rejected" />
            </el-select>
          </div>
          <div>
            <el-button
              v-if="filterSelected == 'approved'"
              v-on:click="exportSelected"
              type="info"
              >Export Selected</el-button
            >
          </div>
          <div style="clear: both"></div>
        </div>
        <div>
          <table>
            <tr>
              <th v-if="filterSelected == 'approved'"></th>
              <th><p>CUSTOMER</p></th>
              <th><p>PLAINTIFF NAME</p></th>
              <th><p>DEFENDANTS</p></th>
              <th><p>ADDRESS</p></th>
              <th><p>CASE TYPE</p></th>
              <th><p>FILES</p></th>
              <th><p>STATUS</p></th>
              <th style="width: 120px"><p></p></th>
            </tr>
            <CaseSummaryList
              v-for="(item, index) in this.allSubmissions"
              :key="index"
              :submission="item"
              :filter="filterSelected"
              :selectedIds="selectedIds"
            />
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CaseSummaryList from '@/components/molecules/CaseSummaryList'
  import { getAllForms, exportSelected } from '../services/CloudFunctions'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import { useRouter } from 'vue-router'

  export default {
    name: 'AdminView',
    components: {
      CaseSummaryList,
      PulseLoader,
    },
    beforeCreate() {
      var sessionHash = localStorage.getItem('sessionHash')
      var router = useRouter()
      if (!sessionHash) router.push('/login')
    },
    data() {
      return {
        allSubmissions: null,
        isFormLoading: false,
        filterSelected: 'pending',
        selectedIds: [],
      }
    },
    async mounted() {
      this.isFormLoading = true
      var formSubmissions = await getAllForms(this.filterSelected)
      this.allSubmissions = formSubmissions.data
      this.isFormLoading = false
    },
    methods: {
      async updateFilter() {
        this.isFormLoading = true
        var formSubmissions = await getAllForms(this.filterSelected)
        this.allSubmissions = formSubmissions.data
        this.isFormLoading = false
      },
      async exportSelected() {
        if (this.selectedIds.length > 0) {
          this.isFormLoading = true
          await exportSelected(this.selectedIds)
          setTimeout(() => {
            this.updateFilter()
          }, 3000)
        }
      },
    },
  }
</script>

<style scoped>
  table {
    width: 100%;
    border: 1px solid #000;
    border-spacing: 0px;
  }
  div.center {
    margin: 50px auto 0;
  }

  div.reference {
    align-items: center;
    padding: 10px 30px;
    background-color: #d9d9d9;
    margin: 0 0 30px;
    border-radius: 5px;
  }

  p {
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    width: 100%;
  }

  div.center {
    max-width: 100%;
  }

  tr:nth-child(even) {
    background-color: #d9d9d9;
  }

  th {
    align-items: center;
    padding: 10px 15px;
  }

  h1 {
    display: block;
    width: 100%;
    text-transform: uppercase;
    text-align: left;
    margin: 0 0 30px;
  }

  @media (min-width: 1441px) {
    div.center {
      max-width: 95%;
    }
  }
</style>
