<template>
  <a :href="`/form/${url}`"
    ><el-button type="primary">{{ text }}</el-button></a
  >
</template>

<script>
  export default {
    props: {
      text: String,
      url: String,
    },
  }
</script>

<style scoped>
  a {
    text-decoration: none;
  }
  button {
    box-sizing: border-box;
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 5px;
    margin: 0 0 0 auto;
  }
</style>
